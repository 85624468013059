import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  FormProvider,
  useForm,
  useWatch,
  Control,
  FieldValues,
  UseFormSetValue,
  useFieldArray,
  UseFormRegister,
} from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Row, Col } from "reactstrap";
import moment from "moment";
import _ from "lodash";
import { H1, LeftPanel } from "../../../components";
import Loading, { LoadingPurple } from "../../../components/Loading";
import { SuccessButton } from "../../../components/new/Button";
import { NutriTitle, HR } from "../../../components/new/Title";
import SaveBottom from "../../../components/SaveBottom";
import { 
  FrequencyCheckGroup,
  CheckGroup,
  RadioGroup,
} from "../../../components/3.0";
import { TextInput, TextArea, Select } from "atoms";
import NumberInput, {
  NumberInputRegister,
  transform,
} from "../../../components/3.0/NumberInput";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { ptBR } from "date-fns/locale";
import {
  RemoveButton,
  AddIcon,
  AddButton,
  Title,
  Subtitle,
  SearchInput,
  EnergyIcon,
  Section,
} from "./styles";
import DAYS_OF_WEEK from "../../../utils/daysOfWeek";
import PERIODS from "../../../utils/periods";
import SystemContext from "../../../context/SystemContext";
import ClientContext from "../../../context/ClientContext";
import MealsService from "../../../services/MealsService";
import AnamnesisService from "../../../services/AnamnesisService";
import PDFService from "../../../services/PDFService";
import { RemoveIcon } from "../styles";
import NutriSchema from "./schema";

interface MethodsProps {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
}

const Resumo: React.FC<MethodsProps> = () => {
  return (
    <>
      <Section>
        <NutriTitle>Consulta atual</NutriTitle>
        <HR />
        <div className='grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12'>
          <div className='col-span-8'>
            <TextInput name="consultationDate" label="Data da Consulta" type="date" />
           </div>
        </div>
      </Section>
      <Section>
        <NutriTitle>
          Anamnese Alimentar e Nutricional
        </NutriTitle>
        <HR />
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className='col-span-8'>
            <RadioGroup
              name="yourAlimentation"
              label="Como você considera sua alimentação"
              options={["Muito ruim", "Ruim", "Razoável", "Boa", "Muito boa"]}
            />
          </div>
          <div className='col-span-4'>
            <TextArea
              name="gainWeight.moment"
              label="Tempo de Início de Ganho de Peso"
              placeholder="Digite aqui o tempo de início de ganho de peso"
            />
          </div>
          <div className='col-span-4'>
            <TextArea
              name="gainWeight.speed"
              label="Velocidade de Ganho de Peso"
              placeholder="Digite aqui a velocidade de ganho de peso"
            />
          </div>
          <div className='col-span-4'>
            <NumberInput name="greaterWeight.weight" label="Maior peso" />
          </div>
          <div className='col-span-4'>
            <NumberInput name="greaterWeight.age" label="Idade do maior peso" />
          </div>
          <div className='col-span-4'>
            <NumberInput name="lowerWeight.weight" label="Menor peso" />
          </div>
          <div className='col-span-4'>
            <NumberInput name="lowerWeight.age" label="Idade do menor" />
          </div>
          <div className='col-span-8'>
            <RadioGroup
              name="actualWeight"
              label="Em relação ao seu peso, atualmente está"
              options={["Perdendo", "Mantendo", "Ganhando"]}
            />
          </div>
          <div className='col-span-8'>
            <TextArea
              name="orientationDifficulties"
              label="Dificuldades de adesão às orientações"
              placeholder="Descreva aqui eventuais dificuldades"
            />
          </div>
          <div className='col-span-8'>
            <TextArea
              name="nutritionalAnamnesisObservations"
              label="Observações da anamnese nutricional"
            />
          </div>
        </div>
      </Section>
    </>
  );
};

const Metas: React.FC<MethodsProps> = (props) => {
  return (
    <>
      <Section>
        <NutriTitle>Metas</NutriTitle>
        <HR />
        <div className='grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8'>
          <div className='col-span-3'>
            <Select
              name="goals.caloriesRange"
              options={[
                [1000, 1200],
                [1300, 1500],
                [1600, 1800],
                [1900, 2100],
                [2200, 2400],
                [2500, 2700],
                [2800, 3000],
                [3100, 3300],
                [3400, 3600],
                [3700, 3900],
              ].map(([min, max]) => ({
                label: `${min}-${max} Kcal`,
                value: `${min},${max}`,
              }))}
              label="Consumo calórico"
            />
          </div>
          <div className='col-span-8'>
            <TextArea name="goals.observations" label="Observações das metas" />
          </div>
        </div>
      </Section>
    </>
  );
};

const PadraoAlimentar: React.FC<MethodsProps> = ({ control }) => {
  const foodPreference = useWatch({
    control,
    name: "foodPreference",
  });

  return (
    <>
      <Section>
        <NutriTitle>
          Padrão Alimentar
        </NutriTitle>
        <HR />
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className='col-span-8'>
            <RadioGroup
              name="foodPreference"
              label="Qual seu padrão alimentar?"
              options={["Tradicional", "Vegetariano", "Vegano"]}
            />
          </div>
          <div
            className='col-span-8'
            style={{
              opacity: foodPreference === "Tradicional" ? 0.2 : 1,
              pointerEvents: foodPreference === "Tradicional" ? "none" : "all",
            }}
          >
            <TextArea
              name="uneatenFoods"
              label="Alimentos não consumidos"
              placeholder="Liste aqui os alimentos"
            />
          </div>
          <div className='col-span-8'>
            <TextArea
              name="foodPatternObservations"
              label="Observações do padrão alimentar"
            />
          </div>
        </div>
      </Section>
    </>
  );
};

interface IMeal extends MethodsProps {
  name: string;
  label: string;
}
const Meal: React.FC<IMeal> = (props) => {
  const [showFoods, setShowFoods] = useState(false);
  const { foods } = useContext(SystemContext);
  const [searchText, setSearchText] = useState("");
  const { name, label, control, register, setValue } = props;
  const doesMeal = useWatch({
    control,
    name: `meals.${name}.do`,
  });
  const mealFoods = useFieldArray({
    control,
    name: `meals.${name}.foods`,
  });
  const mealFoodsWatch = useWatch({
    control,
    name: `meals.${name}.foods`,
  });

  useEffect(() => {
    if (doesMeal === false) {
      setValue(`meals.${name}.foods`, []);
    }
  }, [doesMeal, name, setValue]);

  const getFoods = useCallback(() => {
    return searchText.length === 0
      ? foods
      : foods.filter(
          (food) =>
            food.description.toUpperCase().includes(searchText.toUpperCase()) ||
            food.category.toUpperCase().includes(searchText.toUpperCase())
        );
  }, [foods, searchText]);

  const getMealFoodById = useCallback(
    (id: number) => {
      const filtered = foods.filter((f) => f.id === id);
      return filtered.length === 0 ? {} : filtered[0];
    },
    [foods]
  );

  return (
    <>
      <div className='grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12'>
        <div className='col-span-4'>
          <RadioGroup
            name={`meals.${name}.do`}
            label={`Realiza ${label}?`}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </div>

        
          {doesMeal && (
            <>
              <div className='col-span-4 flex'>
                <AddButton
                  style={{ width: 130, marginRight: 15 }}
                  onClick={() => {
                    //@ts-ignore
                    window
                      .open(
                        "http://44.192.81.173/admin/login/?next=/admin/",
                        "_blank"
                      )
                      .focus();
                  }}
                >
                  Cadastrar
                </AddButton>
                <AddButton onClick={() => setShowFoods(true)}>
                  <AddIcon />
                  Adicionar
                </AddButton>
              </div>
            </>
          )}
      </div>
      <div className='grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12 pt-4 pb-8'>
        {mealFoods.fields.map((field, index) => {
          //@ts-ignore
          const food = getMealFoodById(Number(field.foodId));
          return (
            <div className='col-span-8 flex flex-row bg-gray-200 rounded p-4 items-center justify-center' key={field.id}>
              <RemoveButton
                style={{ marginRight: 10 }}
                onClick={() => mealFoods.remove(index)}
              />
              <NumberInputRegister
                {...register(`meals.${name}.foods.${index}.portion`)}
                //@ts-ignore
                label={`Porção de "${food.description}"`}
                suffix=" gramas"
                onChange={(e) => {
                  setValue(
                    `meals.${name}.foods.${index}.portion`,
                    //@ts-ignore
                    transform.output(e.target.value, " gramas")
                  );
                }}
                //@ts-ignore
                defaultValue={field.portion}
                className="bg-transparent rounded border-white ml-4"
              />
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <EnergyIcon />
                <Title style={{ marginBottom: 0, textTransform: "none" }}>
                  {`${Intl.NumberFormat("pt-BR", {
                    maximumFractionDigits: 1,
                  }).format(
                    (_.get(food, ["energyKcal"]) / 100 || 0) *
                      _.get(mealFoodsWatch, [index, "portion"], 100)
                  )} Kcal`}
                </Title>
              </div>
            </div>
          );
        })}
      </div>

      <LeftPanel
        large={false}
        open={showFoods}
        onClose={() => setShowFoods(false)}
      >
        <Title>Cadastro de alimentos</Title>
        <Subtitle>
          Você está adicionando <strong>{label}</strong>
        </Subtitle>
        <SearchInput
          placeholder="Pesquisar Alimentos"
          //@ts-ignore
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div
          style={{
            flex: 1,
            overflowY: "scroll",
            marginLeft: -17,
            marginRight: -17,
          }}
        >
          {getFoods().map((food) => (
            <div
              key={food.id}
              className="p-4 hover:bg-gray-200 rounded transition-all"
              onClick={() => {
                mealFoods.append({ id: food.id, foodId: food.id, portion: 100 });
                setShowFoods(false);
              }}
            >
              <Title
                style={{ textTransform: "none", color: "var(--purple-light)" }}
              >
                {food.description}
              </Title>
              <Subtitle>{food.category}</Subtitle>
            </div>
          ))}
        </div>
      </LeftPanel>
    </>
  );
};

const RecordatorioAlimentar: React.FC<MethodsProps> = (props) => {
  const { control } = props;
  const betweenMeals = useWatch({
    control,
    name: "betweenMeals",
  });
  const haveDieted = useWatch({
    control,
    name: "foodSurvey.haveDieted",
  });

  return (
    <>
      <Section>
        <NutriTitle>
          Recordatório Alimentar
        </NutriTitle>
        <HR />
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className='col-span-8'>
            <TextArea
              name="foodSurvey.uneatenFoods"
              label="Alimentos que não consome"
            />
          </div>
          <div className='col-span-8'>
            <NumberInput
              name="numberOfMeals"
              label="Quantas refeições por dia?"
              suffix=" refeições diárias"
            />
          </div>
          <div className='col-span-8'>
            <Meal name="breakfast" label="Café da manhã" {...props} />
          </div>
          <div className='col-span-8'>
            <Meal name="morningSnack" label="Lanche da manhã" {...props} />
          </div>
          <div className='col-span-8'>
            <Meal name="lunch" label="Almoço" {...props} />
          </div>
          <div className='col-span-8'>
            <Meal name="afternoonSnack" label="Lanche da tarde" {...props} />
          </div>
          <div className='col-span-8'>
            <Meal name="dinner" label="Janta" {...props} />
          </div>
          <div className='col-span-8'>
            <Meal name="eveningSnack" label="Ceia" {...props} />
          </div>
          <div className='col-span-8'>
            <Meal name="extraMeal" label="Refeição extra" {...props} />
          </div>
          <div className='col-span-8'>
            <TextArea
              name="abuseFoodMeal"
              label="Você exagera no consumo alimentar em alguma refeição?"
              placeholder="Descreva aqui eventuais exageros alimentares"
            />
          </div>
          <div className='col-span-8'>
            <RadioGroup
              name="foodCompulsion"
              label="Compulsão alimentar"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div className='col-span-8'>
            <CheckGroup
              name="eatingDisorder"
              label="Transtorno alimentar"
              options={["Não", "Bulimia", "Anorexia", "Ortorexia", "Outros"]}
              renderOther={
                <TextArea
                  name="othersEatingDisorders"
                  label="Outros"
                  placeholder="Descreva aqui..."
                />
              }
            />
          </div>
          <div className='col-span-4'>
            <RadioGroup
              name="betweenMeals"
              label="Possui o hábito de beliscar ou buscar ativamente alimentos entre as refeições?"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div
            className='col-span-4'
            style={{
              opacity: betweenMeals ? 1 : 0.2,
              pointerEvents: betweenMeals ? "all" : "none",
            }}
          >
            <TextArea
              name="foodBetweenMeals"
              label="Quais alimentos costuma beliscar entre refeições?"
              placeholder="Liste aqui os alimentos"
            />
          </div>
          <div className='col-span-8'>
            <CheckGroup
              name="foodIntolerances"
              label="Alguma intolerância ou alergia alimentar?"
              options={["Não", "Lactose", "Glúten", "Outros"]}
              renderOther={
                <TextArea
                  name="othersFoodIntolerances"
                  label="Outros"
                  placeholder="Descreva aqui..."
                />
              }
            />
          </div>
          <div className='col-span-8'>
            <FrequencyCheckGroup
              name="foodEvaluation"
              label="Frequência alimentar"
              frequecies={["1x", "2x", "3x", "4x", "5x", "Mais de 5x"]}
              periods={["dia", "semana", "mês"]}
              options={[
                "Saladas",
                "Frutas",
                "Doces",
                "Frituras",
                "Peixes",
                "Embutidos",
                "Temperos artificais",
              ]}
            />
          </div>
          <div className='col-span-8'>
            <FrequencyCheckGroup
              name="liquidIngestion"
              label="Ingestão de líquidos"
              frequecies={[
                "250ml",
                "500ml",
                "1000ml",
                "1500ml",
                "2000ml",
                "Mais de 2000ml",
              ]}
              periods={["dia", "semana", "mês"]}
              options={[
                "Água",
                "Sucos naturais",
                "Energéticos",
                "Sucos Artificiais",
                "Chás",
                "Cafés",
                "Refrigerantes",
                "Álcool",
                "Outros",
              ]}
            />
          </div>
          <div className='col-span-4'>
            <RadioGroup
              name="foodSurvey.useSalt"
              label="Usa sal em alimentos prontos?"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div className='col-span-4'>
            <RadioGroup
              name="foodSurvey.useSugar"
              label="Usa açúcar?"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div className='col-span-8'>
            <RadioGroup
              name="foodSurvey.useSweetener"
              label="Usa adoçante?"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div className='col-span-4'>
            <RadioGroup
              name="foodSurvey.haveDieted"
              label="Já fez dieta?"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div className='col-span-4'
            style={{
              opacity: haveDieted ? 1 : 0.2,
              pointerEvents: haveDieted ? "all" : "none",
            }}
          >
            <TextArea
              name="foodSurvey.dietOrientation"
              label="Orientado por quem?"
              placeholder=""
            />
          </div>
          <div className='col-span-8'>
            <TextArea
              name="foodHistoryObservations"
              label="Observações do recordatório alimentar"
            />
          </div>
        </div>
      </Section>
    </>
  );
};

const Digestao: React.FC<MethodsProps> = (props) => {
  return (
    <>
      <Section>
        <NutriTitle>
          Digestão e Intestino
        </NutriTitle>
        <HR />
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className='col-span-8'>
            <RadioGroup
              name="digestionAndFoodFunction.chewing"
              label="Como é sua mastigação?"
              options={["Lenta", "Normal", "Rápida"]}
            />
          </div>
          <div className='col-span-8'>
            <RadioGroup
              name="digestionAndFoodFunction.intestinalFunction"
              label="Como é sua função intestinal?"
              options={["Constipado", "Normal", "Diarréia"]}
            />
          </div>
          <div className='col-span-8'>
            <NumberInput
              name="digestionAndFoodFunction.evaluationNumber"
              label="Quantas evacuações na semana"
              suffix=" evacuações semanais"
            />
          </div>
          <div className='col-span-8'>
            <CheckGroup
              name="digestionAndFoodFunction.symptoms"
              label="Você possui alguns dos sintomas digestivos?"
              options={["Não", "Refluxo", "Gastrite", "Náuseas", "Vômitos"]}
            />
          </div>
          <div className='col-span-8'>
            <TextArea
              name="digestionObservations"
              label="Observações da digestão"
            />
          </div>
        </div>
      </Section>
    </>
  );
};

const Sono: React.FC<MethodsProps> = (props) => {
  const { control } = props;
  const middleOfTheNightToEat = useWatch({
    control,
    name: "rest.middleOfTheNightToEat",
  });

  return (
    <>
      <Section>
        <NutriTitle>
          Sono
        </NutriTitle>
        <HR />
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className='col-span-4'>
            <NumberInput
              name="rest.wakeUp"
              label="Horário que acorda e dorme"
              format="##:## H"
              suffix=" H"
            />
          </div>
          <div className='col-span-4'>
            <NumberInput
              name="rest.sleepIn"
              label="Horário que dorme"
              format="##:## H"
              suffix=" H"
            />
          </div>
          <div className='col-span-4'>
            <NumberInput
              name="rest.sleepHours"
              label="Horas de sono na semana"
              format="## H"
              suffix=" H"
            />
          </div>
          <div className='col-span-4'>
            <NumberInput
              name="rest.sleepHoursInWeekend"
              label="Horas de sono no fim de semana"
              format="## H"
              suffix=" H"
            />
          </div>
          <div className='col-span-8'>
            <TextArea
              name="rest.sleepDescription"
              label="Como costuma ser o sono"
              placeholder="Descreva aqui o sono"
            />
          </div>
          <div className='col-span-4'>
            <RadioGroup
              name="rest.middleOfTheNight"
              label="Acorda no meio da noite?"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div className='col-span-4'>
            <RadioGroup
              name="rest.middleOfTheNightToEat"
              label="Acorda no meio da noite para comer?"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div className='col-span-8'
            style={{
              opacity: middleOfTheNightToEat ? 1 : 0.2,
              pointerEvents: middleOfTheNightToEat ? "all" : "none",
            }}>
            <TextArea
              name="rest.middleOfTheNightFoods"
              label="Quais alimentos come no meio da noite?"
              placeholder="Liste aqui os alimentos"
            />
          </div>
          <div className='col-span-8'>
            <TextArea name="restObservations" label="Observações do sono" />
          </div>
        </div>
      </Section>
    </>
  );
};

const Treino: React.FC<MethodsProps> = (props) => {
  return (
    <>
      <Section>
        <NutriTitle>
          Treino
        </NutriTitle>
        <HR />
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className='col-span-8'>
            <TextArea
              name="trainingHour"
              label="Qual o horário do seu treino?"
              placeholder="Descreva aqui"
            />
          </div>
        </div>
      </Section>
    </>
  );
};

const Observacoes: React.FC<MethodsProps> = (props) => (
  <>
    <Section>
      <NutriTitle>
        Observações
      </NutriTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
        <div className='col-span-8'>
          <TextArea
            name="observations"
            label="Observações adicionais"
            placeholder="Descreva aqui oberservações adicionais"
          />
        </div>
        <div className='col-span-8'>
          <TextArea
            name="clientGoals"
            label="Metas acordadas com o cliente"
            placeholder="Digite aqui"
          />
        </div>
      </div>
    </Section>
  </>
);

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
}

export const PlanoAlimentar: React.FC = () => {
  const methods = useForm();
  const { reset, control, setValue, handleSubmit } = methods;
  const { meals } = useContext(SystemContext);
  const { clientId } = useParams<ClientParams>();
  const [showMeals, setShowMeals] = useState({});
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [breakfastTime, setBreakfastTime] = useState<Date | null>(null);
  const [lunchTime, setLunchTime] = useState<Date | null>(null);
  const [dinnerTime, setDinnerTime] = useState<Date | null>(null);
  const [snackTime, setSnackTime] = useState<Date | null>(null);
  const data = useWatch({ control });
  const finished = useWatch({
    control,
    name: "finish",
  });
  const weekDays = useWatch({
    control,
    name: "weekDays",
  });

   const onSave = useCallback(
    async (data, published = false) => {
      setSaving(true);
      try {
        var { weekDays, observations } = data;
        let sundayPlan = null;
        weekDays.forEach((w) => {
          if (w.weekDay === "sunday") {
            sundayPlan = w.plan;
          }
        });
        weekDays = weekDays.map((w) => {
          const { plan, weekDay } = w;
          return {
            plan: (sundayPlan || plan).map((p) => {
              const { meals, period } = p;
              return {
                period,
                meals: (meals || []).filter((m) => !m.removed).map((m) => m.id),
                breakfastTime: breakfastTime !== null ? breakfastTime : p.breakfastTime,
                lunchTime: lunchTime !== null ? lunchTime : p.lunchTime,
                snackTime: snackTime !== null ? snackTime : p.snackTime,
                dinnerTime: dinnerTime !== null ? dinnerTime : p.dinnerTime,
              };
            }),
            weekDay,
          };
        });
        await MealsService.update(data._id, { weekDays, observations, finish: published });
        NotificationManager.success("Sucesso", "Plano alimentar salvo");
      } catch (e) {
        NotificationManager.error("Erro ao salvar plano alimentar");
      }
      setSaving(false);
    },
    [setSaving, breakfastTime, lunchTime, snackTime, dinnerTime]
  ); 

  const getWeekMeals = useCallback(
    async (tryAgain: boolean = true) => {
      setLoading(true);
      if (clientId) {
        try {
          const today = moment().format("DD/MM/YYYY");
          const weekMealsResponse = await MealsService.getWeekMeals(
            clientId,
            today
          );
          const weekMeals = _.get(weekMealsResponse, ["data", "data"]);
          reset(weekMeals);
        } catch (e) {
          if (tryAgain) {
            await MealsService.generateWeekMeals(clientId);
            getWeekMeals(false);
          }
        }
      }
      setLoading(false);
    },
    [clientId, reset, setLoading]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await onSave(data, true);
        getWeekMeals()
      } catch (errors) {
        //@ts-ignore
        errors.inner.forEach((currentError) => {
          console.log(currentError.path, {
            type: currentError.type,
            message: currentError.message,
          });
        });
        NotificationManager.error(
          "Preencha todos os campos obrigatórios para publicar"
        );
      }
    },
    [onSave, getWeekMeals]
  );

  const getMeals = useCallback(() => {
    return searchText.length === 0
      ? meals
      : meals.filter(
          (meal) =>
            meal.name.toUpperCase().includes(searchText.toUpperCase()) ||
            meal.description.toUpperCase().includes(searchText.toUpperCase()) ||
            meal.type.toUpperCase().includes(searchText.toUpperCase())
        );
  }, [meals, searchText]);

  

  const downloadPlano = useCallback(async () => {
    setDownloading(true);
    try {
      const reponse = await PDFService.foodPlan(clientId);
      //@ts-ignore
      window.open(URL.createObjectURL(reponse.data));
    } catch (e) {}
    setDownloading(false);
  }, [clientId, setDownloading]);

  useEffect(() => {
    getWeekMeals();
  }, [getWeekMeals]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingPurple style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: 100 }}>
        {_.isEmpty(weekDays) ? (
          <>
            <H1 showLine={false} color="nutri">
              Publique uma ficha nutri para gerar o Plano Alimentar
            </H1>
          </>
        ) : (
          <>
            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <SuccessButton type="button" onClick={downloadPlano}>
                  {downloading && <Loading />}
                  Baixar plano alimentar
                </SuccessButton>
              </Col>
            </Row>
            <Section>
              <NutriTitle>
                Plano Alimentar
              </NutriTitle>
              <HR />
              <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
                <div className='col-span-8'>
                  <TextArea name="observations" label="Observações" />
                </div>
              </div>

              {(weekDays || [])
              .filter((w) => w.weekDay === "sunday")
              .map((weekDay, weekDayIndex) => (
                <React.Fragment key={weekDay.weekDay}>
                  {_.get(weekDay, ["plan"], []).map((plan, planIndex) => (
                    <React.Fragment key={plan.period}>
                      <Row>
                        <Col>
                          <H1 color="nutri" showLine>
                            {`${PERIODS[plan.period] || plan.period}`}
                          </H1>
                        </Col>
                        {plan.period === "breakfast" ? (
                          <Col>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                              <TimePicker
                                label="Horário Recomendado"
                                value={breakfastTime !== null ? breakfastTime : plan.breakfastTime}
                                onChange={(newTime) => {
                                  setBreakfastTime(newTime);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Col>
                          ) : plan.period === "lunch" ? (
                            <Col>
                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <TimePicker
                                  label="Horário Recomendado"
                                  value={lunchTime !== null ? lunchTime : plan.lunchTime}
                                  onChange={(newTime) => {
                                    setLunchTime(newTime);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                            </Col>
                          ) : plan.period === "snack" ? (
                            <Col>
                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <TimePicker
                                  label="Horário Recomendado"
                                  value={snackTime !== null ? snackTime : plan.snackTime}
                                  onChange={(newTime) => {
                                    setSnackTime(newTime);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                            </Col>
                          ) : plan.period === "dinner" ? (
                            <Col>
                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <TimePicker
                                  label="Horário Recomendado"
                                  value={dinnerTime !== null ? dinnerTime : plan.dinnerTime}
                                  onChange={(newTime) => {
                                    setDinnerTime(newTime);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                            </Col>
                          ) : <></>
                        }  
                      </Row>
                      <Row>
                        <Col style={{ paddingLeft: 34, display: "flex" }}>
                          <AddButton
                            style={{ width: 130, marginRight: 15 }}
                            onClick={() => {
                              //@ts-ignore
                              window
                                .open(
                                  "https://hauxfoods.herokuapp.com/admin/login/?next=/admin/",
                                  "_blank"
                                )
                                .focus();
                            }}
                          >
                            Cadastrar
                          </AddButton>
                          <AddButton
                            onClick={() => {
                              setShowMeals({
                                planIndex,
                                weekDayIndex,
                                weekDay: weekDay.weekDay,
                                period: plan.period,
                              });
                            }}
                          >
                            <AddIcon />
                            Adicionar
                          </AddButton>
                        </Col>
                      </Row>
                      {_.get(plan, ["meals"], []).map((meal) => (
                        <Row
                          key={meal.id}
                          style={{
                            display: meal.removed ? "none" : "flex",
                            alignItems: "center",
                          }}
                        >
                          <RemoveIcon
                            onClick={() => {
                              const newMeals = (plan.meals || []).map((m) =>
                                m.id === meal.id ? { ...m, removed: true } : m
                              );
                              setValue(
                                `weekDays.${weekDayIndex}.plan.${planIndex}.meals`,
                                newMeals,
                                { shouldTouch: true }
                              );
                            }}
                          />
                          <img
                            src={meal.image}
                            alt={meal.id}
                            style={{ height: 150 }}
                          />
                          <Col>
                            <Title>{meal.name}</Title>
                            <Subtitle>{`${meal.difficulty} - ${
                              meal.time
                            }min - ${meal.calories}Kcal`}</Subtitle>
                          </Col>
                        </Row>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </Section>
            

            <LeftPanel
              large={false}
              open={!_.isEmpty(showMeals)}
              onClose={() => setShowMeals({})}
            >
              <Title>Cadastro de Alimentos</Title>
              <Subtitle>
                Selecione aqui os alimentos para{" "}
                <strong>{//@ts-ignore
                `${DAYS_OF_WEEK[showMeals.weekDay]} - ${
                  //@ts-ignore
                  PERIODS[showMeals.period]
                }`}</strong>
              </Subtitle>
              <SearchInput
                placeholder="Pesquisar as Refeições"
                //@ts-ignore
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  marginLeft: -17,
                  marginRight: -17,
                }}
              >
                {getMeals().map((meal) => (
                  <div
                    key={meal.id}
                    className="container"
                    style={{ alignItems: "center", justifyContent: "center" }}
                    onClick={() => {
                      const newMeals = _.get(
                        weekDays,
                        [
                          //@ts-ignore
                          showMeals.weekDayIndex,
                          "plan",
                          //@ts-ignore
                          showMeals.planIndex,
                          "meals",
                        ],
                        []
                      );
                      newMeals.push(meal);
                      setValue(
                        //@ts-ignore
                        `weekDays.${showMeals.weekDayIndex}.plan.${
                          //@ts-ignore
                          showMeals.planIndex
                        }.meals`,
                        newMeals,
                        { shouldTouch: true }
                      );
                      setShowMeals({});
                    }}
                  >
                    <img
                      src={meal.image}
                      alt={`${meal.id}`}
                      style={{ width: 200 }}
                    />
                    <Title
                      style={{
                        textTransform: "none",
                        color: "var(--purple-light)",
                      }}
                    >
                      {meal.name}
                    </Title>
                    <Subtitle>{`${meal.type} - ${
                      meal.calories
                    } KCal`}</Subtitle>
                  </div>
                ))}
              </div>
            </LeftPanel>
          </>
        )}

        <SaveBottom
          saving={saving}
          touched={true}
          showPublish={true}
          color={"nutri"}
          onSave={() => {
            onSave(data);
          }}
          published={finished}
        />
      </form>
    </FormProvider>
  );
};

const MaisMulher: React.FC<MethodsProps> = () => {
  return (
    <>
      <Row>
        <Col>
          <H1 color="nutri" showLine>
            Haux Mais Mulher
          </H1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            name="foodGoals"
            label="Quais são os objetivos da Sra. quanto à sua alimentação?"
            placeholder="Descreva aqui os objetivos"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            name="menopauseChanges"
            label="A Sra. percebeu alguma alteração na sua alimentação durante o climatério?"
            placeholder="Descreva aqui as alterações"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            name="feelingChoices"
            label="A Sra. percebe que modifica suas escolhas alimentares de acordo com seus sentimentos (tristeza, ansiedade, frustração,...)? Explique."
            placeholder="Descreva aqui as alterações"
          />
        </Col>
      </Row>
    </>
  );
};

const Nutri: React.FC = () => {
  const methods = useForm();
  const history = useHistory();
  const { foods } = useContext(SystemContext);
  const { reset, control, formState, handleSubmit } = methods;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { clientId, anamnesisId } = useParams<ClientParams>();
  const { isMaisMulher } = useContext(ClientContext);
  const finished = useWatch({ control, name: "finish" });
  const data = useWatch({ control });

  const getAnamnesisData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await AnamnesisService.getAllAnamnesis(
        "nutri",
        clientId
      );
      const item = _.find(_.get(response, ["data", "data", "items"], []), [
        "_id",
        anamnesisId,
      ]);
      if (item) {
        reset({ ...item, client: clientId });
      } else {
        throw new Error();
      }
    } catch (e) {
      NotificationManager.error("Erro ao obter dados da anamnese");
      history.push(`/client/${clientId}/nutri`);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, anamnesisId, reset, setLoading, history, foods]);

  useEffect(() => {
    getAnamnesisData();
  }, [getAnamnesisData]);

  const getDataTransform = (data, finished = false) => {
    const values = data;

    if (finished) {
      values.finish = true;
    }

    return values;
  };

  const onSave = useCallback(
    async (data, published = false) => {
      setSaving(true);
      try {
        const values = getDataTransform(data, published);
        await AnamnesisService.insertAnamnesis("nutri", values);
        NotificationManager.success(
          `Ficha ${published ? "salva" : "publicada"} com sucesso`
        );
      } catch (e) {
        NotificationManager.error("Erro ao salvar ficha");
      }
      setSaving(false);
    },
    [setSaving]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await NutriSchema.validate(getDataTransform(data, true), {
          abortEarly: false,
        });
        await onSave(data, true);
        getAnamnesisData();
      } catch (errors) {
        //@ts-ignore
        errors.inner.forEach((currentError) => {
          console.log(currentError.path, {
            type: currentError.type,
            message: currentError.message,
          });
          // setError(currentError.path, {
          //   type: currentError.type,
          //   message: currentError.message,
          // });
        });
        NotificationManager.error(
          "Preencha todos os campos obrigatórios para publicar"
        );
      }
    },
    [onSave, getAnamnesisData]
  );

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingPurple style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: 80 }}>
          <Resumo {...methods} />
          <Metas {... methods} />x
          {isMaisMulher && <MaisMulher {...methods} />}
          <PadraoAlimentar {...methods} />
          <RecordatorioAlimentar {...methods} />
          <Digestao {...methods} />
          <Sono {...methods} />
          <Treino {...methods} />
          <Observacoes {...methods} />
          <SaveBottom
            saving={saving}
            touched={formState.isDirty || !finished}
            showPublish={!finished}
            color={"nutri"}
            onSave={() => {
              onSave(data);
            }}
            published={finished}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default Nutri;
