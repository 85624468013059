import React, { useCallback, useState , useContext} from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Icon } from '@iconify/react';
import { INotification } from 'services/Notifications'
import ClientContext from "context/ClientContext";

interface INavBarItem {
    title: string;
    id?: string;
    url?: string;
    onClick?: (item: INavBarItem) => void;
    onClickNotification?: (notification: INotification) => void;
    options?: INavBarItem[];
    notifications?: INotification[];
    error?: string;
    loading?: boolean;
}

const NavBarItem: React.FC<INavBarItem> = ({
    title,
    url,
    onClick,
    onClickNotification,
    options,
    notifications,
    error,
    loading
}) => {
    const { client } = useContext(ClientContext);
    const [onlyNewNotifications, setOnlyNewNotifications] = useState(true);
    const history = useHistory();

    const handleClick = useCallback((item: INavBarItem) => {
        if (onClick) {
            onClick(item)
            return;
        }

        if (null != item.url) {
            history.push(item.url)
        }
    }, [history])
    
    const getFiltered = useCallback(() => {
        var result = [...(notifications || []) ]
        if (onlyNewNotifications) {
            result = result.filter((n) => !n.read)
        }
        if (client) {
            result = result.filter((n) => (n.client || {})._id == client?._id)
        }

        return result
    }, [notifications, onlyNewNotifications, client]);

    function getIconByType(type: string) {
        switch(type) {
            case 'Nutri':
                return "dashicons:food";
            case 'Doctor':
                return "jam:medical";
            default:
                return "solar:medical-kit-linear";
        }
    }

    return (
        <>
            <li className="nav-item relative" key={history.location.pathname}>
                <button
                    className="flex center items-center peer px-5 py-2 text-white h-20 hover:bg-[#1e1630]"
                    onClick={() => {
                        if (!(options?.length)) {
                            handleClick({ title, url})
                        }
                    }}
                >
                    {title}
                    {loading && (
                        <div role="status">
                            <svg aria-hidden="true" className="w-6 h-6 ml-2 text-gray-200 animate-spin fill-purple-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                    {!_.isEmpty(error) && (
                        <span className="ml-4 inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                            <span className="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            {error}
                        </span>
                    )}
                    {!_.isEmpty(notifications) && (
                        <span className="inline-flex items-center justify-center minw-6 h-6 ml-2 p-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full">
                            {getFiltered().length}
                        </span>
                    )}
                    {(!_.isEmpty(options) || !_.isNil(notifications)) && (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                        </svg>
                    )}
                </button>
                {(!_.isEmpty(options) || !_.isNil(notifications)) && (
                    <div className={`absolute min-w-full hidden peer-hover:flex hover:flex flex-col bg-white drop-shadow-lg z-50 overflow-y-scroll ${notifications != undefined ? 'fixed right-0' : ''}`}>
                        {options?.map((option) => (
                            <a
                                key={option.url || option.id}
                                className="px-5 py-3 cursor-pointer hover:bg-gray-200 whitespace-nowrap"
                                onClick={() => handleClick(option)}
                            >{option.title}</a>
                        ))}
                        
                        {!_.isEmpty(notifications) && (
                            <div className="h-[300px] w-[600px] grid place-items-center overflow-y">
                                <div className="w-full h-full bg-gray-100 mx-auto border shadow-sm p-4 px-8">
                                    <div className="inline-flex items-center justify-between w-full">
                                        <h3 className="font-bold text-xl sm:text-2xl text-gray-800 mb-4">{title}</h3>
                                        <div>
                                            <label className="relative inline-flex items-center mr-5 cursor-pointer">
                                                <input type="checkbox" value="" className="sr-only peer" checked={onlyNewNotifications} onClick={() => setOnlyNewNotifications(!onlyNewNotifications)}/>
                                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#3D2E61]"></div>
                                                <span className="ml-3 text-sm font-medium text-gray-900">Somente não visualizadas</span>
                                            </label>
                                            <br/>
                                        </div>
                                    </div>

                                    {client && (
                                        <div className="w-full mb-8">
                                            <div className="flex justify-end w-full">
                                                <label className="relative flex items-right mr-5 cursor-pointer">
                                                    <span className="ml-3 text-sm font-medium text-gray-900">Somente as notificações de <strong>{client?.name}</strong></span>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {getFiltered().map((notification) => (
                                        <div
                                            key={notification._id}
                                            className="mt-2 px-4 py-2 bg-white rounded-lg shadow w-full group hover:bg-[#624E92] transition-all cursor-pointer"
                                            onClick={() => onClickNotification?.(notification)}
                                        >
                                            <div className=" inline-flex items-center justify-between w-full">
                                                <div className="inline-flex items-center">
                                                    <Icon icon={getIconByType(notification.type)} width={20} height={20} className="text-[#3D2E61] opacity-60 group-hover:text-white transition-all" />
                                                    <h3 className="font-bold text-base text-[#3D2E61] ml-4 group-hover:text-white transition-all">{notification.title}</h3>
                                                    {!notification.read && <span className="ml-4 inline-flex items-center bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                                        <span className="w-2 h-2 mr-1 bg-blue-500 rounded-full"></span>
                                                        Não visualizada por você
                                                    </span>}
                                                    {!!notification.read && <span className="ml-4 inline-flex items-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                                        <Icon icon="mdi:check-all" width={16} height={16} className="text-green-800 mr-1" />
                                                        Visualizada por você
                                                    </span>}
                                                </div>
                                                <p className="text-xs text-gray-500 group-hover:text-white transition-all">
                                                    {moment(notification.createdAt).fromNow()}
                                                </p>
                                            </div>
                                            <p className="text-sm text-gray-500 group-hover:text-white transition-all">
                                                {notification.message}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {_.isEmpty(notifications) && !_.isNil(notifications) && (
                            <div className="h-[300px] w-[600px] grid place-items-center overflow-y">
                                <p className="text-gray-400 font-bold">Nenhuma nova notificação</p>
                            </div>
                        )}
    
                    </div>
                )}
            </li>
        </>
    );
}

export default NavBarItem;