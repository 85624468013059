import * as Yup from "yup";

interface IHealthProblem {
  value?: string[];
  other?: string;
}

interface IActivity {
  value?: string[];
  other?: string;
}

interface IGoal {
  weight?: number;
  suggestionWeight?: number;
  imc?: number;
  caloriesNih?: number;
  minCalories?: number;
  maxCalories?: number;
  unachievable?: boolean;
  date?: Date;
  goalDate?: string;
  caloriesRange?: string;
}

export interface IPhysical {
  consultationDate: string;
  finish?: boolean;
  goal: string;
  healthProblems?: IHealthProblem;
  stressLevel: string;
  musclePains?: IHealthProblem;
  difficultiesInMoving?: string[];
  pastActivities?: IActivity;
  yourActivities?: IActivity;
  leisureTimeActivity: string;
  workActivity: string;
  weeklyAerobicMinutes?: number;
  weeklyForceTimes?: number;
  levelYourCurrentActivities?: string;
  observations?: string;
  hauxApproach?: string;
  goals?: IGoal;
}

//@ts-ignore
const schema: Yup.ObjectSchema<IPhysical> = Yup.object().shape({
  consultationDate: Yup.string().required('Campo obrigatório').min(10),
  finish: Yup.boolean(),
  goal: Yup.string().required('Campo obrigatório'),
  healthProblems: Yup.object<IHealthProblem>().shape({
    value: Yup.array().of(Yup.string()).optional(),
    other: Yup.string().optional(),
  }).optional(),
  stressLevel: Yup.string().required('Campo obrigatório'),
  musclePains: Yup.object<IHealthProblem>().shape({
    value: Yup.array().of(Yup.string()).optional(),
    other: Yup.string().optional(),
  }).optional(),
  difficultiesInMoving: Yup.array().of(Yup.string()),
  pastActivities: Yup.object<IActivity>().shape({
    value: Yup.array().of(Yup.string()).optional(),
    other: Yup.string().optional(),
  }).optional(),
  yourActivities: Yup.object<IActivity>().shape({
    value: Yup.array().of(Yup.string()).optional(),
    other: Yup.string().optional(),
  }).optional(),
  leisureTimeActivity: Yup.string().required('Campo obrigatório'),
  workActivity: Yup.string().required('Campo obrigatório'),
  weeklyAerobicMinutes: Yup.number(),
  weeklyForceTimes: Yup.number(),
  levelYourCurrentActivities: Yup.string(),
  observations: Yup.string(),
  hauxApproach: Yup.string(),
});

export default schema;
