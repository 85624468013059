import axios from "../middlewares/axios";

export interface IPermission {
  _id: string;
  name: string;
  slug: string;
  isDeleted: boolean;
}

interface IUserResponse {
  items: IPermission[],
  totalCount: number
}

class PermissionService {
  static async getAll(): Promise<IUserResponse> {
    const url = `/permissions?sortBy=slug`;
    const response = await axios.get<{
      data: IUserResponse
    }>(url)
    return response.data.data;
  }
}

export default PermissionService;