import styled from "styled-components";
import TechHighlightSVG from "../../assets/icons/tech-icon.svg";
import PhysicalHighlightSVG from "../../assets/icons/physical-icon.svg";
import MedHighlightSVG from "../../assets/icons/med-icon.svg";
import NutriHighlightSVG from "../../assets/icons/nutri-icon.svg";
import PsicoHighlightSVG from "../../assets/icons/psico-icon.svg";
import ArrowDownSVG from "../../assets/icons/arrow-down-white.svg";
import AccordionPlusSVG from "../../assets/icons/accordion-plus.svg";
import AccordionMinusSVG from "../../assets/icons/accordion-minus.svg";
import WeightSVG from "../../assets/icons/weight.svg";
import IMCSVG from "../../assets/icons/imc.svg";
import FatSVG from "../../assets/icons/fat.svg";
import AbdominalSVG from "../../assets/icons/abdominal.svg";
import HandGripSVG from "../../assets/icons/handgrip.svg";
import StressLevelSVG from "../../assets/icons/stress.svg";

interface ITimelineIcon {
  anamnesis: string;
}
const getIcon = (anamnesis: string) => {
  if (anamnesis === "tech") {
    return TechHighlightSVG;
  }
  if (anamnesis === "nutri") {
    return NutriHighlightSVG;
  }
  if (anamnesis === "med") {
    return MedHighlightSVG;
  }
  if (anamnesis === "psico") {
    return PsicoHighlightSVG;
  }
  if (anamnesis === "physical") {
    return PhysicalHighlightSVG;
  }
  if (anamnesis === "round") {
    return NutriHighlightSVG;
  }
};
export const TimelineIcon = styled.div<ITimelineIcon>`
  width: 40px;
  height: 40px;
  flex: none;
  margin-right: 16px;
  transition: all 0.3s ease, padding 0.8s linear;
  background: url("${({ anamnesis }) => getIcon(anamnesis)}");
`;

export const TimelineSeparator = styled.div`
  height: 4px;
  width: 88px;
  background-color: var(--separator-color);
`;

export const TimelineItem = styled.div`
  display: flex;
  width: 80%;
  align-items: flex-start;
  padding: 12px 0;

  div {
    flex: 1;

    h1 {
      font-family: var(--font-heavy);
      font-size: 20px;
      margin: 0;
    }
    h2 {
      font-family: var(--font-heavy);
      font-size: 14px;
      text-transform: uppercase;
      color: var(--text);
      margin: 0;
    }
    span,
    div {
      font-family: var(--font-regular);
      font-size: 14px;
      color: var(--text);
    }
  }
`;

export const Subtitle = styled.h1`
  font-size: 20px;
  font-family: var(--font-heavy);
  color: var(--resume);
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  height: 86px;
  background: var(--neutral-color);
  border-radius: 8px;
  flex: 2;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--primary-dark-color);

    p {
      margin-bottom: 0;
      font-family: var(--font-heavy);
      font-size: 24px;
      line-height: 0;

      small {
        font-family: var(--font-heavy);
        font-size: 14px;
      }
    }
  }
`;

export const ContentIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  padding: 18px 0;
  gap: 16px;
`;

export const StykuItem = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text);
  align-items: center;
  height: 270px;

  small {
    font-family: var(--font-heavy);
    font-size: 24px;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 14px;
    text-transform: uppercase;
  }

  p {
    font-family: var(--font-heavy);
    font-size: 17px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
`;

export const StykuLeftImage = styled.img`
  height: 90%;
`;
interface IArrowDown {
  collapsed: boolean;
}
export const ArrowDown = styled.img.attrs({
  src: ArrowDownSVG,
})<IArrowDown>`
  margin-left: 20px;
  transition: all 0.3s ease;
  transform: ${({ collapsed }) =>
    collapsed ? "rotateZ(0)" : "rotateZ(180deg)"};
`;

export const Accordion = styled.div`
  flex: 1;
  background-color: var(--neutral-color);
  padding: 16px;
  border-radius: 8px;
  margin: 8px;
  transition: all 0.3s ease;
  height: fit-content;

  :hover {
    background-color: var(--neutral-low-color);
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-family: var(--font-heavy);
    font-size: 12px;
    color: var(--primary-color);
    margin: 0;
    text-transform: uppercase;
  }
`;

export const AccordionMinus = styled.img.attrs({
  src: AccordionMinusSVG,
})`
  height: 32px;
  width: 32px;
`;

export const AccordionPlus = styled.img.attrs({
  src: AccordionPlusSVG,
})`
  height: 32px;
  width: 32px;
`;

export const Weight = styled.img.attrs({
  src: WeightSVG,
})`
  height: 36px;
  width: 36px;
  margin-right: 16px;
`;

export const IMC = styled(Weight).attrs({
  src: IMCSVG,
})``;

export const Fat = styled(Weight).attrs({
  src: FatSVG,
})``;

export const Abdominal = styled(Weight).attrs({
  src: AbdominalSVG,
})``;

export const HandGrip = styled(Weight).attrs({
  src: HandGripSVG,
})``;

export const StressLevel = styled(Weight).attrs({
  src: StressLevelSVG,
})``;
