import styled from "styled-components";

export const HR = styled.div`
  border: 1px solid var(--neutral-color);
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const PrimaryTitle = styled.h1`
  font-family: Graviola Soft Heavy;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--resume-color);
  margin-bottom: 0;
`;

export const PrimaryLabel = styled.h1`
  font-family: Graviola Soft Heavy;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--primary-dark-color);
  margin-bottom: 0;
`;

export const MedTitle = styled.h1`
  font-family: Graviola Soft Heavy;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--med-color);
  margin-bottom: 0;
`;

export const TechTitle = styled.h1`
  font-family: Graviola Soft Heavy;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--tech);
  margin-bottom: 0;
`;

export const PhysicalTitle = styled.h1`
  font-family: Graviola Soft Heavy;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--physical);
  margin-bottom: 0;
`;

export const NutriTitle = styled.h1`
  font-family: Graviola Soft Heavy;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--nutri);
  margin-bottom: 0;
`;
