import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.css"

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );


ReactDOM.createRoot(document.getElementById("root")).render(<App />);
