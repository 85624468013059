import React from "react";
import {
  Row,
  ItemWrapper,
  Title,
  Description,
  Value,
  Calories,
  EnergyIcon,
  Image,
} from "./styles";
import PERIODS from "../../utils/periods";

interface IFood {
  name: string;
  description: string;
  calories: number;
  period: string;
  image?: string;
}

const Food: React.FC<IFood> = ({
  name,
  description,
  calories,
  period,
  image,
}) => {
  return (
    <ItemWrapper>
      <Row>
        {image && (
          <Row>
            <Image src={image} />
          </Row>
        )}
        <Row
          style={{ flex: 1, flexDirection: "column", alignItems: "flex-start" }}
        >
          <Row style={{ width: "100%" }}>
            <Title style={{ marginRight: 35 }}>{(name || "").toUpperCase()}</Title>

            <Row>
              <Row>
                <EnergyIcon />
                <Value>
                  {Intl.NumberFormat("pt-BR", {
                    maximumFractionDigits: 1,
                  }).format(calories)}
                </Value>
                <Calories>KCAL</Calories>
              </Row>
            </Row>
          </Row>

          {description.split('//').map((d) => (
            <Row key={d} style={{ alignItems: "start" }}>
              <Description >{d.trim()}</Description>
            </Row>
            ))}

          
          <Row style={{ alignItems: "start" }}>
            <Description>{PERIODS[period]}</Description>
          </Row>
        </Row>
      </Row>
    </ItemWrapper>
  );
};

export default Food;
