import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { TextInput, TextArea } from "atoms";
import Infos from "../../components/new/Infos";
import { ButtonSave } from "../../components/SaveBottom";
import { LoadingPurple } from "../../components/Loading";
import {
    FormProvider,
    useForm
  } from "react-hook-form";
  import { selectUser } from "../../redux/ducks/Auth/selectors";
import ReportsService from "../../services/ReportsService";
import _ from  "lodash";
import moment from "moment";

const REPORT_TYPE = "forensic";

interface ClientParams {
    clientId?: string;
    reportType?: string;
    reportId?: string;
}

interface Report {
    _id: string;
    updatedAt: string;
    text: string;
    user: {
        _id?: string;
        name?: string;
    }
}

const LaudoPericial: React.FC = () => {
    const methods = useForm();
    const { reset, handleSubmit } = methods;
    const { reportType, reportId } = useParams<ClientParams>();
    const user = useSelector(selectUser);
    const [loading, setLoading] = useState(false)
    const [newComment, setNewComment] = useState(false)
    const [reports, setReports] = useState<Report[]>([])
    
    const getAll = useCallback(
        async () => {
          setLoading(true);
          try {
            const response = await ReportsService.getItems(reportId);
            const data = _.get(response, ["data", "data", "items"], [])
            setReports(data)
          } catch (errors) {
            NotificationManager.error(
              "Erro ao obter dados"
            );
          }
          setLoading(false);
        },
        [reportId, setReports,setLoading]
    );

    useEffect(() => {
        const current = _.first(reports.filter((r) => r.user._id === user._id))
        reset(current)
    }, [reports, user, reset])

    useEffect(() => {
        getAll()
    }, [getAll])

    const getTitle = (type) => {
      switch(type) {
        case "forensic": return "Laudo Pericial";
        case "checkup": return "Check-Up Saúde";
        case "bariatric": return "Bariátrica";
        case "post-bariatric": return "Pós-Bariátrica";
        case "round": return "Round da Equipe";
        default: return "Laudo";
      }
    }

    const onSubmit = useCallback(
        async (data) => {
          setLoading(true);
          try {
              const newData = { 
                report: reportId,
                user: user._id,
                type: REPORT_TYPE,
                ...data 
              }
              await ReportsService.insertItems(reportId, newData)
              NotificationManager.success(
                "Parecer salvo com sucesso"
              );
          } catch (errors) {
            NotificationManager.error(
              "Erro ao salvar dados"
            );
          }
          setLoading(false);
          getAll()
        },
        [reportId, user, setLoading, getAll]
    );

      const generate = useCallback(
        async () => {
          setLoading(true);
          try {
            const url =  ReportsService.generateReportUrl(reportId)
            //@ts-ignore
            const pdfWindow = window.open();
            pdfWindow.location.href = url; 
            NotificationManager.success(
            "Download feito com sucesso"
            );
          } catch (errors) {
            NotificationManager.error(
              "Erro ao baixar laudo"
            );
          }
          setLoading(false);
        },
        [reportId, setLoading]
      );

      if (loading) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingPurple style={{ height: 100 }} />
          </div>
        );
      }

    return (
        <div>
            <FormProvider {...methods}>
                <form style={{ paddingBottom: 50 }} onSubmit={handleSubmit(onSubmit)}>
                    <Infos title={getTitle(reportType)}>
                        <TextArea name="text" label="Seu parecer"/>
                        {user.profileType === 'Doctor' && (
                          <>
                            {(reportType === "forensic" || reportType === "bariatric") && <TextArea name="finalText" label="Parecer final"/>}
                            {reportType === "bariatric" && <TextInput name="bariatricSurgeryReleaseDate" label="Encaminhamento para cirugia bariátrica na data:" type="date"/>}
                          </>
                        )}
                        {/* <div className="px-6 pt-6">
                          <label key={`${newComment}`} className="relative inline-flex items-center cursor-pointer" onClick={() => setNewComment(!newComment)}>
                            <input type="checkbox" checked={newComment} className="sr-only peer" />
                            <div className="w-11 h-6 bg-purple-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-purple-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900">Publicar como novo comentário</span>
                          </label>
                        </div> */}
                        <ButtonSave style={{ marginTop: 30 }}>Salvar</ButtonSave>
                    </Infos>
                </form>
            </FormProvider>
           <Infos title="Todos os pareceres">
                <ButtonSave style={{marginTop: 30}} onClick={generate}>Baixar laudo</ButtonSave>
                {reports.map((report) => (
                    <div style={{marginTop: 50}} key={report._id}>
                        <strong>{report.user.name} - {moment(report.updatedAt).format("DD/MM/YYYY")}</strong>
                        <br/>
                        <small><div dangerouslySetInnerHTML={{ __html: report.text }} /></small>
                    </div>
                ))}
            </Infos>
                    </div>
    );
}

export default LaudoPericial;